import * as React from "react"
import {graphql} from "gatsby"
import {Link} from "gatsby"
import Footer from "../components/footer";

// markup
const IndexPage = ({data}) => {
  return (
    <>
      <main>
        <title>Gratis bitcoin | Seriøse selskaper som gir deg BTC gratis</title>

          <h1>Gratis bitcoin og annen kryptovaluta</h1>
          <p>Her er en oversikt over seriøse tilbydere som gir deg <strong>gratis bitcoins (BTC)</strong> eller annen kryptovaluta for å registrere deg og/eller foreta handler i deres tjeneste:</p>
          <ul>
              {data.allSanityArticle.edges.map(({node},i) => {
                  return(
                      <li key={i}><Link to={'/'+node.slug.current}>{node.title}</Link></li>
                  )
              })}
          </ul>
      </main>
      <Footer/>
    </>
  )
}

export default IndexPage


export const query = graphql`
query BankQuery {
      allSanityArticle(filter: {slug: {current: {ne: null}}, channel: {slug: {current: {eq: "gratisbitcoin"}}}}, sort: {order: ASC, fields: priority}) {
        edges {
          node{
            slug{
                current
            }
            title
            intro
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
`