import React from "react"

const Footer = () =>{
    return(
        <footer>
            Tipsene på denne siden er av <strong>informasjonsart</strong> og må <strong>ikke forveksles</strong> med finansiell rådgivning.
            Om du planlegger å kjøpe mer bitcoins eller øvrig kryptovaluta utover det du får gratis, husk at du aldri må investere mer enn
            du har råd til å tape - dette gjelder både Bitcoin (BTC), tokens og øvrig kryptovaluta.
            Tipsene inneholder annonselenker.
        </footer>
    )
}

export default Footer